import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { handleResponse, authHeader } from '../../internals/authservice/authentication.service.js';
import Popup from '../../components/popups.js';
import { api_url } from "../../internals/constants";
import {JoinSchedule} from "./JoinSchedule";
import {DetailSchedule} from "./DetailSchedule";
import {
  Clock,
  CheckCircle2,
  Users,
  CircleChevronRight,
  CircleChevronLeft
} from 'lucide-react';


const API_URL = api_url;

const ClassCard = ({ classInfo, onViewDetails, onJoinLeave }) => {
  const isPastClass = moment(classInfo.fecha + ' ' + classInfo.hora_fin, 'YYYY-MM-DD HH:mm:ss').isBefore(moment());

  const StatusBadge = () => {
    if (isPastClass) {
      return (
        <span className="absolute top-3 right-3 px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
          Finalizada
        </span>
      );
    }
    if (classInfo.user_inside) {
      return (
        <div className="absolute top-3 right-3 flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium bg-green-50 text-green-700">
          <CheckCircle2 size={12} />
          <span>Inscrito</span>
        </div>
      );
    }
    return null;
  };

  const MainContent = () => (
    <div className="flex flex-col gap-3">
      <div className="flex items-start justify-between">
        <h3 className={`font-bold text-base ${
          isPastClass ? 'text-gray-500' : 'text-slate-800'
        }`}>
          {classInfo.nombre_clase}
        </h3>
      </div>

      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <div className="flex items-center gap-1.5">
          <Clock size={14} className="text-slate-500" />
          <span className="text-sm text-slate-600">
            {classInfo.hora_inicio.substring(0, 5)} - {classInfo.hora_fin.substring(0, 5)}
          </span>
        </div>
        {!isPastClass && (
          <div className="flex items-center gap-1.5">
            <Users size={14} className="text-slate-500" />
            <span className="text-sm text-slate-600">
              {classInfo.apuntados}/{classInfo.aforo}
            </span>
          </div>
        )}
      </div>
    </div>
  );

  const ActionSection = () => {
    if (isPastClass) return null;

    return (
      <div className="mt-3 pt-3 border-t border-gray-100">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onJoinLeave(classInfo);
          }}
          className={`w-full py-2 rounded-lg text-sm font-medium transition-all
            ${classInfo.user_inside 
              ? 'bg-gray-100 text-gray-900 hover:bg-gray-100' 
              : 'bg-blue-50 text-blue-900 hover:bg-blue-100'
            } focus:outline-none focus:ring-2 focus:ring-offset-1 ${
              classInfo.user_inside 
                ? 'focus:ring-gray-200' 
                : 'focus:ring-blue-200'
            }`}
        >
          {classInfo.user_inside ? 'Cancelar plaza' : 'Reservar plaza'}
        </button>
      </div>
    );
  };

  return (
    <div
      className={`group border border-1 border-gray-200 relative bg-white rounded-xl p-4 transition-all
        ${isPastClass 
          ? 'opacity-75' 
          : 'cursor-pointer'
        }`}
    >
      <div className={`absolute top-0 left-0 w-2 h-full rounded-l-xl transition-colors ${
        isPastClass 
          ? 'bg-gray-200' 
          : classInfo.user_inside 
            ? 'bg-green-400' 
            : 'bg-blue-400'
      }`} />

      <div className="ml-3">
        <StatusBadge />
        <MainContent />
        <ActionSection />
      </div>
    </div>
  );
};

const WeekNavigation = ({ weekStart, onWeekChange }) => {
  return (
    <div className="flex flex-col sm:flex-row items-center justify-between gap-4 mb-6 px-2">
      <div className="flex items-center gap-3">
        <button
          onClick={() => onWeekChange(-1)}
          className="p-2 text-slate-600 hover:bg-slate-100 rounded-full transition-colors"
          aria-label="Previous week"
        >
          <CircleChevronLeft size={24} />
        </button>
        <h2 className="text-lg sm:text-xl font-semibold text-slate-800">
          Semana del {weekStart.format('DD MMMM YYYY')}
        </h2>
        <button
          onClick={() => onWeekChange(1)}
          className="p-2 text-slate-600 hover:bg-slate-100 rounded-full transition-colors"
          aria-label="Next week"
        >
          <CircleChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

const DayCard = ({ date, classes = [], onViewDetails, onJoinLeave }) => {
  const isToday = date.isSame(moment(), 'day');

  return (
    <div className={`bg-white rounded-xl overflow-hidden shadow-sm border ${
      isToday ? 'border-blue-200 ring-1 ring-blue-200' : 'border-gray-200'
    }`}>
      <div className={`px-4 py-3 border-b ${
        isToday 
          ? 'bg-blue-50 border-blue-200' 
          : 'bg-gray-50 border-gray-200'
      }`}>
        <h3 className={`font-medium capitalize ${
          isToday ? 'text-blue-800' : 'text-gray-800'
        }`}>
          {date.format('dddd')}
          <span className="ml-2 text-sm">
            {date.format('DD/MM')}
          </span>
        </h3>
      </div>

      <div className="p-3 space-y-2">
        {classes.length > 0 ? (
          classes.map((classInfo) => (
            <ClassCard
              key={classInfo.id}
              classInfo={classInfo}
              onViewDetails={onViewDetails}
              onJoinLeave={onJoinLeave}
            />
          ))
        ) : (
          <p className="text-sm text-gray-500 py-2 text-center">
            No hay clases
          </p>
        )}
      </div>
    </div>
  );
};


const ClassCalendar = () => {
  const [weekStart, setWeekStart] = useState(moment().startOf('isoWeek'));
  const [classes, setClasses] = useState({});
  const [popupMessage, setPopupMessage] = useState('');
  const [popupTitle, setPopupTitle] = useState('');
  const [popupError, setPopupError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    moment.locale('es');  // Set locale to Spanish
    fetchWeekClasses(weekStart);
  }, [weekStart]);

  const fetchWeekClasses = async (start) => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    try {
      const response = await fetch(`${API_URL}/clases/${start.year()}/${start.month() + 1}`, requestOptions);
      const data = await handleResponse(response);
      const groupedClasses = data.reduce((acc, cls) => {
        const date = moment(cls.fecha).format('YYYY-MM-DD');
        if (!acc[date]) acc[date] = [];
        acc[date].push(cls);
        return acc;
      }, {});
      setClasses(groupedClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  useEffect(() => {
    fetchWeekClasses(weekStart);
  }, [weekStart]);

  const handleWeekChange = (weeks) => {
    setWeekStart(moment(weekStart).add(weeks, 'weeks'));
  };


  const handleJoinLeaveClass = async (classId, isLeaving) => {
  const action = isLeaving ? 'leave' : 'join';
  try {
    const response = await fetch(`${API_URL}/clases/${action}/${classId}`, { method: 'GET', headers: authHeader() });
    const data = await handleResponse(response);
    setJoinModalOpen(false)
    if (data.status === 1) {
      setPopupMessage(`Te has ${isLeaving ? 'desapuntado de' : 'apuntado a'} la clase correctamente`);
      setPopupTitle('Éxito');
      setPopupError(false);
    } else {
      setPopupMessage(`Error al ${isLeaving ? 'desapuntarte de' : 'apuntarte a'} la clase`);
      setPopupTitle('Error');
      setPopupError(true);
    }
    setShowPopup(true);
    fetchWeekClasses(weekStart);
  } catch (error) {
    console.error(`Error ${isLeaving ? 'leaving' : 'joining'} class:`, error);
    setPopupMessage('Ocurrió un error inesperado');
    setPopupTitle('Error');
    setPopupError(true);
    setShowPopup(true);
  }
};
  const handleViewDetails = (classInfo) => {
    setSelectedClass(classInfo);
    setDetailModalOpen(true);
  };

  const checkCapacity = async (classId) => {
    try {
      const response = await fetch(`${API_URL}/clases/check-capacity/${classId}`, {
        method: 'GET',
        headers: authHeader()
      });
      const data = await handleResponse(response);

      if (!data.hasCapacity) {
        await fetchWeekClasses(weekStart);
      }
      return data;
    } catch (error) {
      console.error('Error checking capacity:', error);
      throw error;
    }
  };

  const handleJoinLeaveClick = async (classInfo) => {
    if (!classInfo.user_inside) {
      try {
        const capacityCheck = await checkCapacity(classInfo.id);
        if (!capacityCheck.hasCapacity) {
          setPopupMessage('Lo sentimos, el aforo de la clase está completo');
          setPopupTitle('Aforo Completo');
          setPopupError(true);
          setShowPopup(true);
          return;
        }
      } catch (error) {
        setPopupMessage('Error al comprobar el aforo de la clase');
        setPopupTitle('Error');
        setPopupError(true);
        setShowPopup(true);
        return;
      }
    }

    setSelectedClass(classInfo);
    setJoinModalOpen(true);
};

  return (
      <div className="max-w-7xl mx-auto p-4">
        <WeekNavigation
            weekStart={weekStart}
            onWeekChange={handleWeekChange}
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {[...Array(7)].map((_, index) => {
            const date = moment(weekStart).add(index, 'days');
            const dateStr = date.format('YYYY-MM-DD');
            return (
                <DayCard
                    key={dateStr}
                    date={date}
                    classes={classes[dateStr] || []}
                    onViewDetails={handleViewDetails}
                    onJoinLeave={handleJoinLeaveClick}
                />
            );
          })}
        </div>
        {showPopup && (
            <Popup
                message={popupMessage}
                title={popupTitle}
                error={popupError}
                duration={3000}
                onClose={() => setShowPopup(false)}
            />
        )}
        <JoinSchedule
            isOpen={joinModalOpen}
            onClose={() => setJoinModalOpen(false)}
            classInfo={selectedClass}
            onJoinLeave={(classId, isLeaving) => handleJoinLeaveClass(classId, isLeaving)}
        />
      </div>
  );
};

export default ClassCalendar;